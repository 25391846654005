import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const showNotification = (type: NotificationType) => (message: React.ReactNode, args?: Omit<any, 'message'>) =>
  notification[type]({ placement: 'topRight', message, ...args });

export const showSuccessNotification = showNotification('success');
export const showInfoNotification = showNotification('info');
export const showWarningNotification = showNotification('warning');
export const showErrorNotification = showNotification('error');
