import { postRequest, getRequest, ResponsePromise } from '../../requests/request';
import { USERS, WORKING_DAYS } from '../urls';

//@ts-ignore
export const openDay = async (id: number, point_id: number, doughAmount: number | null): ResponsePromise<any> => {
  return postRequest(`${USERS}/${id}/open_day`, { point_id: point_id, dough_count: doughAmount });
};

//@ts-ignore
export const closeDay = async (id: number, point_id: number, freeAmount, doughAmount: number| null): ResponsePromise<any> => {
  return postRequest(`${USERS}/${id}/close_day`, { point_id: point_id, extra_money: freeAmount, dough_count: doughAmount });
};


//@ts-ignore
export const getActiveOrders = async (id: number): ResponsePromise<{ working_day: Summary }> => {
  return getRequest(`${WORKING_DAYS}/${id}`);
};


//@ts-ignore
export const getWorkingDayTotal = async (id: number): ResponsePromise<Total> => {
  return getRequest(`${WORKING_DAYS}/${id}/total`);
};

type Summary = {
  orders: SummaryOrder[],
  date: string;
}

type SummaryOrder = {
  items: OrderItemName[],
  total: number;
  discounted_total: number;
  id: number;
}

export type OrderItemName = {
item_id: number;
category_id: number;
  name: string,
  count: number,
}

export type Total = {
  money: {
    cash: number,
    card: number },
  working_day_total: TotalItem[]
}

export type TotalItem = {
  category: string,
  cost: number,
  count: number,
  name: string
}