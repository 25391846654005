import { postRequest, ResponsePromise } from '../../requests/request';
import { LOGIN, LOGOUT} from '../urls';


//@ts-ignore
export const doLogin = async (username: string, password: string): ResponsePromise<any> => {
  return postRequest(LOGIN, { username, password });
};


//@ts-ignore
export const doLogout = async (): ResponsePromise<any> => {
  return postRequest(LOGOUT);
};