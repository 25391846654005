import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableCard } from '../../components/Table';
import { Link } from 'react-router-dom'

import { Status } from '../../types';
import { useColumns } from './useColumns';
import styles from './reports.module.css';
import { useGetReports } from '../../services/hooks/useGetReports'
import { Sortings } from '../../services/api/types';
import { Pagination } from '../../types';
import { DateRange, getDateRange } from '../../components/DateRange'

type ReportsProps = {
};

const pagination: Pagination = { page: 0, size: 1e2 };
const initialSort: Sortings = [{ field: 'startedAt', order: 'desc' }];

export const Reports = ({ }: ReportsProps): React.ReactElement => {
  const [[from, to], setDateRange] = useState(getDateRange('day'));
  const [reports, statusReportsFetching, getReports] = useGetReports();

  useEffect(() => {
    getReports({
      filter: {
        from: from,
        to: to,
      }
    });
  }, [from, to]);

  //@ts-ignore
  const count = reports?.report.orders?.length ?? 0;
  const columns = useColumns();

  const onChangeDateRange = (value: [string, string]) => {
    setDateRange(value);
  };

  const amountCash = reports?.report.money.reduce((acc, sum) => acc + sum.cash, 0) ?? 0;
  const amountCard = reports?.report.money.reduce((acc, sum) => acc + sum.card, 0) ?? 0;
  const totalAmount = amountCash + amountCard;

  return (
    <div className={styles.root}>
      <div className={styles.buttonRowRight__container}>
        <Link to={'/'} className={styles.link__button}>
          На главную
        </Link>
      </div>
      <DateRange
        value={[from, to]}
        onChange={onChangeDateRange}
        withButtons
        withDatePicker
        isLoading={statusReportsFetching === Status.loading}
      />

      <TableCard
        count={count}
        loading={statusReportsFetching === Status.loading}
        onRefresh={() => getReports({
          filter: {
            from: from,
            to: to,
          }
        })}
        customTitle={<div className={styles.total__container}>
          <span>{`Касса: ${Math.round(totalAmount)} руб`}</span>
          <span>{`Наличные: ${Math.round(amountCash)} руб`}</span>
          <span>{`Карта: ${Math.round(amountCard)} руб`}</span>

        </div>}
      >
        <Table
          rowKey="tripId"
          dataSource={reports?.report.orders.reverse() ?? []}
          loading={statusReportsFetching === Status.loading}
          columns={columns}
          // pagination={{
          //   pageSize: pagination.size,
          //   current: pagination.value.page,
          //   total: count,
          //   showSizeChanger: true,
          //   onShowSizeChange: (page, size) => pagination.setPagination({ page, size }),
          //   onChange: (page, size) =>
          //     pagination.setPagination({ page: page, size: size ? size : pagination.value.size }),
          // }}
          // sort={sort.value}
          // onSortChange={sort.setSort}
          scroll={{ x: true }}
        />
      </TableCard>
    </div>
  );
};
