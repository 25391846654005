import { User } from '../../services/api/user';
import { Status } from '../../types';
import { createReducer } from 'typesafe-actions';
import { getUserActions } from './actions';

export interface UserState {
  data?: User;
  status: Status;
}

const initialState: UserState = {
  status: Status.initial,
};

export const user = createReducer(initialState)
//@ts-ignore
  .handleAction(getUserActions.request, state => ({
    ...state,
    status: Status.loading,
  }))
  //@ts-ignore
  .handleAction(getUserActions.success, (state, { payload }) => {
    return {
      ...state,
      status: Status.success,
      data: payload
    };
  })

  //@ts-ignore
  .handleAction(getUserActions.failure, state => ({
    ...state,
    status: Status.failure,
  }));
