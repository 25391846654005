// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activeOrder_order__container__WKtyP{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 1rem 2rem;\n    border: 1px solid black;\n    border-radius: 1rem;\n    width: 10rem;\n}\n\n.activeOrder_order__container__WKtyP:hover{\nborder: 1px solid #1677ff;\ncursor: pointer;\n}\n\n.activeOrder_summary__1CY1b{\n    margin-top: 1rem;\n    font-weight: 600;\n}\n\n.activeOrder_item__zt9w8{\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.5rem;\n}\n\n.activeOrder_close__-c6XP{\n    display: flex;\n    justify-content: center;\n    margin-top: 0.5rem;\n}\n\n.activeOrder_button__vaQuw{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/ActiveOrder/activeOrder.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;AACA,yBAAyB;AACzB,eAAe;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".order__container{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 1rem 2rem;\n    border: 1px solid black;\n    border-radius: 1rem;\n    width: 10rem;\n}\n\n.order__container:hover{\nborder: 1px solid #1677ff;\ncursor: pointer;\n}\n\n.summary{\n    margin-top: 1rem;\n    font-weight: 600;\n}\n\n.item{\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.5rem;\n}\n\n.close{\n    display: flex;\n    justify-content: center;\n    margin-top: 0.5rem;\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order__container": "activeOrder_order__container__WKtyP",
	"summary": "activeOrder_summary__1CY1b",
	"item": "activeOrder_item__zt9w8",
	"close": "activeOrder_close__-c6XP",
	"button": "activeOrder_button__vaQuw"
};
export default ___CSS_LOADER_EXPORT___;
