import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

export function useGetWorkingDayId(): number {
  return useSelector((state: RootState) => {
    const activeWorkingDayId = state.user.data.active_working_day?.id;

    return activeWorkingDayId;
  });
}
