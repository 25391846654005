import { ErrorCodes } from './errorCodes';

export class RequestException {
  public code: ErrorCodes;

  public constructor(code: ErrorCodes) {
    this.code = code;
  }
}

export * from './errorCodes';
