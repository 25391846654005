import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

export function useGetUserId(): number {
  return useSelector((state: RootState) => {
    const userId = state.user.data.id

    return userId;
  });
}
