// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reports_root__tKghG {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    padding: 2rem;\n  }\n\n  .reports_total__container__bdnsg{\n    display: flex;\n    flex-direction: column;\n    gap:0.5rem\n  }\n\n  .reports_link__button__ILbMc{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n    color: #fff;\n    background-color: #1677ff;\n    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n}\n\n\n.reports_link__button__ILbMc:hover{\n    background-color: #4096ff;\n}    \n\n.reports_buttonRowRight__container__RCDoN{\n    display: flex;\n    justify-content: right;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Reports/reports.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB;EACF;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,0CAA0C;IAC1C,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    padding: 2rem;\n  }\n\n  .total__container{\n    display: flex;\n    flex-direction: column;\n    gap:0.5rem\n  }\n\n  .link__button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n    color: #fff;\n    background-color: #1677ff;\n    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n}\n\n\n.link__button:hover{\n    background-color: #4096ff;\n}    \n\n.buttonRowRight__container{\n    display: flex;\n    justify-content: right;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "reports_root__tKghG",
	"total__container": "reports_total__container__bdnsg",
	"link__button": "reports_link__button__ILbMc",
	"buttonRowRight__container": "reports_buttonRowRight__container__RCDoN"
};
export default ___CSS_LOADER_EXPORT___;
