import { getRequest, postRequest, putRequest, ResponsePromise } from '../../requests/request';
import { ORDERS } from '../urls';

//@ts-ignore
export const getOrder = async (orderId: number): ResponsePromise<ResponseOrder> => {
  return getRequest(`/${ORDERS}/${orderId}`,);
};

//@ts-ignore
export const sendOrder = async (order: Partial<Order>): ResponsePromise<any> => {
  return postRequest(`/${ORDERS}`, { order });
};

//@ts-ignore
export const updateOrder = async (order: Partial<Order>, id: number): ResponsePromise<any> => {
  return putRequest(`/${ORDERS}/${id}`, { order });
};


//@ts-ignore
export const closeOrder = async (orderId: number): ResponsePromise<any> => {
  return postRequest(`/${ORDERS}/${orderId}/close`);
};

//@ts-ignore
export const removeOrder = async (id: number): ResponsePromise<any> => {
  return postRequest(`/${ORDERS}/${id}/reject`);
};

export type ResponseOrder = {
    order: {
      discount: number | null,
      discounted_total: number;
      order_items?: OrderItem[],
      cash: number,
      card: number,
      total: number;
      payment_type: 'cash' | 'card' | 'mix';
    }
    order_items?: OrderItem[],
}
export type Order = {
  discount: number | null,
  order_items?: OrderItem[],
  cash: number,
  card: number,
  payment_type?: 'cash' | 'card' | 'mix';
  total: number;
}

export type OrderItem = {
  item_id: number,
  item_name: string,
  count: number,
  category_id: number,
  category_name: string,

}

export type OrderItemName = {
  name: string,
  count: number,
}