import { useState, useCallback } from 'react';
import { ResponsePromise } from '../../requests/request';
import { Status } from '../../types';

export const useFetchData = <Initial, Data, Args extends any[]>(
  initialData: Initial,
  fetchData: (...args: Args) => ResponsePromise<Data>,
  handleError?: (error: Error) => void,
): [Data | Initial, Status, (...args: Args) => Promise<Data>, () => void] => {
  const [data, setData] = useState(initialData);
  const [status, setStatus] = useState(Status.initial);

  const fetch = useCallback(
    async (...args: Args) => {
      setStatus(Status.loading);

      try {
        const response = await fetchData(...args);

        if (!response.success) throw new Error();

        // TODO: Update after all APIs will be refactored
        // Right now we have two possible structures: body[content] and body.data[content]
        const data = response.body.data ?? response.body;
        setData(data);
        setStatus(Status.success);

        return data;
      } catch (error) {
        setStatus(Status.failure);
        //@ts-ignore
        handleError?.(error);
        throw error;
      }
    },
    [fetchData, handleError],
  );

  const reset = useCallback(() => {
    setData(initialData);
  }, [initialData]);

  return [data, status, fetch, reset];
};
