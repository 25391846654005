import { connect } from 'react-redux';
import { loginThunk } from '../../state/app/thunk';
import { RootState } from 'typesafe-actions';
import { LoginPage } from './Login';
// export  {LoginPage  as Login}  from './Login'

const mapStateToProps = ({ app }: RootState): LoginState => ({
  isLoggedIn: app.isLoggedIn,
  isLoggedOut: app.isLoggedOut,
});

const mapDispatchToProps: MapDispatchToProps = {
  login: loginThunk,
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginPage);

interface LoginState {
  isLoggedIn: boolean;
  isLoggedOut: boolean;
}

interface MapDispatchToProps {
  login: (username: string, password: string, errorMessage: string) => any;
}
export type LoginProps = MapDispatchToProps & LoginState;
