import { getRequest, ResponsePromise } from '../../requests/request';
import { USER } from '../urls';

//@ts-ignore
export const getUser = async (): ResponsePromise<{ data: User }> => getRequest(USER);

export interface User {
  active_working_day: {
    id: number
  } | null,
  id: number,
  username: string,
  phone_number: string,
  type: Role,
  last_login: string;

}

export enum Role {
  ADMIN = 'admin',
}


type UserActiveOrders = {
  created_at: string
discount: number | null
payment_type: "cash" | 'card'
total: number;
}