import { getRequest, ResponsePromise } from '../../requests/request';
import { POINTS } from '../urls';

//@ts-ignore
export const getPoints = async (): ResponsePromise<{points: Point[]}> => getRequest(POINTS);

//@ts-ignore
export const getItems= async (point_id: number): ResponsePromise<{items: Item[]}> => getRequest(`${POINTS}/${point_id}/items`);

export type Point = {
    id: number,
    name: string,
    address: string,
}

export type Item = {
    id: number;
    category_id: number;
    point_id: string;
    name: string;
    price: number;
};