import React, { FC} from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Login } from '../pages/Login'
import { Order } from '../pages/Order'
import { RootRouterProps } from './';
import { paths } from '../constants/paths';
import { Reports } from '../pages/Reports'
import { Manage } from '../pages/Manage';
import { ErrorPage } from '../pages/ErrorPage';


export const RootRouterComponent: FC<RootRouterProps> = ({ isLoggedIn }) => {

  return (
    <>
      <Routes>
        <Route path={paths.login} element={<Login />} />
        {!isLoggedIn && <Route path='*' element={<Navigate to={paths.login} />}/> }
        {isLoggedIn && 
        <>
          <Route path="/" element={<Order />} />
          <Route path={paths.reports} element={<Reports />} />
          <Route path={paths.manage} element={<Manage />} />
          <Route path="*" element={<ErrorPage />} />
          </>
        }
      </Routes>
    </>
  );
};
