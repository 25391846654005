import React, { useCallback } from 'react';
import { DatePicker, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import cx from 'classnames';
import moment, { unitOfTime, Moment } from 'moment';
import dayjs, {Dayjs} from 'dayjs'
import styles from './dateRange.module.css';

export type DateRangeProps = {
  className?: string;
  onChange?: ([from, to]: [string, string]) => void;
  value: [string, string];
  withButtons?: boolean;
  withDatePicker?: boolean;
  allowClear?: boolean;
  tripsPage?: boolean;
  isLoading?: boolean;
};

export const DateRange = (props: DateRangeProps) => {
  const {
    className,
    value: [from, to],
    onChange,
    withButtons,
    withDatePicker,
    allowClear,
    isLoading,
  } = props;

  const handleRadioChange = useCallback((event: RadioChangeEvent) => onChange?.(event.target.value.split(',')), []);

  const handleDateRangeChange = useCallback(
    (range: [Dayjs | null, Dayjs | null] | null) => {
      onChange?.([range?.[0]?.startOf('day').toISOString() ?? '', range?.[1]?.endOf('day').toISOString() ?? '']);
    },
    [onChange],
  );

  return (
    <div className={cx(styles.container, className)}>
      {withButtons && (
        <Radio.Group
          disabled={isLoading}
          value={[from, to].join()}
          onChange={handleRadioChange}
          className={styles.marginBottom}>
          <Radio.Button value={getDateRange('day').join()}>Сегодня</Radio.Button>
          <Radio.Button value={getDateRange('week').join()}>Неделя</Radio.Button>
          <Radio.Button value={getDateRange('month').join()}>Месяц</Radio.Button>
          <Radio.Button value={getDateRange('year').join()}>Год</Radio.Button>
          <Radio.Button value={['', ''].join()}>Все</Radio.Button>
        </Radio.Group>
      )}
      {withDatePicker && (
        <DatePicker.RangePicker
          className={styles.dateSize}
          value={[from ? dayjs(from) : null, to ? dayjs(to) : null]}
          onChange={handleDateRangeChange}
          allowClear={allowClear}
        />
      )}
    </div>
  );
};

export const getDateRange = (unit: unitOfTime.StartOf): [string, string] => [
  moment().startOf(unit).toISOString(),
  moment().endOf(unit).toISOString(),
];
