// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentType_paymentType__q6Y8p{\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n}\n\n.paymentType_buttonsContainer__M6AWc{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem\n}\n\n.paymentType_button__toLlq{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/PaymentType/paymentType.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B;AACJ;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;AAChB","sourcesContent":[".paymentType{\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n}\n\n.buttonsContainer{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentType": "paymentType_paymentType__q6Y8p",
	"buttonsContainer": "paymentType_buttonsContainer__M6AWc",
	"button": "paymentType_button__toLlq"
};
export default ___CSS_LOADER_EXPORT___;
