import React, { useEffect } from 'react';
import { LoadingSpin } from '../LoadingSpin';
import { Status } from '../../types';
import { MapDispatchToProps, MapStateToProps } from '.';

export type AuthProps = MapDispatchToProps & MapStateToProps;

//@ts-ignore
export const Auth: React.FC<AuthProps> = ({ initializeApp, appStatus, children }) => {
  useEffect(() => {
    initializeApp();
  }, []);

  if (appStatus === Status.initial || appStatus === Status.loading) {
    return <LoadingSpin />;
  }

  return <>{children}</>;
};
