import React from 'react';

export function Manage(): React.ReactElement {

    return (
        <div>
            Здесь добавляем меню
        </div>
    );
}

