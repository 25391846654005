import { RequestException, ErrorCodes } from './exception';
import queryString from 'query-string';


const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  NOT_FOUND: 404,
};
const API_URL = process.env.REACT_APP_API_URL;

const handleResponseStatus = (status: number): void => {
  switch (status) {
    case HTTP_STATUS.UNAUTHORIZED:
      throw new RequestException(ErrorCodes.UNAUTHORIZED);

    default:
      if (status > 400) {
        throw new RequestException(ErrorCodes.SERVER_ERROR);
      }
  }
};

const CT_APPLICATION_JSON = 'application/json';

function buildOptions({ method, headers, auth = true }: BuildOptions): RequestInit {
  const headersObj: HeadersInit = new Headers(headers);
  const options: RequestInit = {
    method,
    headers: headersObj,
  };

  if (auth) {
    options.credentials = 'include';
  }

  return options;
}

export function buildUrl(url: string, queryParams?: string): string {
  const qString = queryParams ? `?${queryParams}` : '';

  return `${url}${qString}`;
}

//@ts-ignore
async function responseResult(response: Response): ResponsePromise<any> {
  handleResponseStatus(response.status);

  try {
    const responseBody = await response.json();

    return {
      success: true,
      body: responseBody || {},
    };
  } catch (error) {
    return {
      success: true,
      body: { data: {} },
    };
  }
}


async function performRequest(
  url: string,
  options: RequestInit,
  queryParams?: string,
  //@ts-ignore
): ReturnType<typeof responseResult> {
  const requestUrl = buildUrl(url, queryParams);
  const response = await fetch(requestUrl, options);    
  if (response.status === HTTP_STATUS.NOT_FOUND) {

    return responseResult(response);
  }

  return responseResult(response);
}

const token = sessionStorage.getItem('token')

export const getRequest: Request = async (url, queryParams, auth) => {
  const options = buildOptions({
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    auth,
  });
  return performRequest(url, options, prepareQueryParams(queryParams));
};

export const putRequest: PostRequest = async (url, body, queryParams, auth) => {
  const defaultOptions = buildOptions({
    method: 'PUT',
    mode: 'no-cors',
    headers: {
      'Content-Type': CT_APPLICATION_JSON,
      'Authorization': `Bearer ${token}`
    },
    auth,
  });

  const options = {
    ...defaultOptions,

    body: JSON.stringify(body),
  };

  return performRequest(url, options, prepareQueryParams(queryParams));
};

export const postRequest: PostRequest = async (url, body, queryParams, auth) => {
  const defaultOptions = buildOptions({
    method: 'POST',
    headers: {
      'Content-Type': CT_APPLICATION_JSON,
      'Authorization': `Bearer ${token}`
    },
    auth,
  });

  const options = {
    ...defaultOptions,

    body: JSON.stringify(body),
  };
  return performRequest(url, options, prepareQueryParams(queryParams));
};

export const deleteRequest: Request = async (url, queryParams) => {
  const options = buildOptions({ method: 'DELETE' });

  return performRequest(url, options, prepareQueryParams(queryParams));
};

const prepareQueryParams = (queryParams?: QueryParams): string | undefined => {
  return queryParams && queryString.stringify(queryParams);
};

export type ResponsePromise<T> = Promise<ResponseResult<T>>;
export type ResponseResult<T> = SuccessResult<T> | ErrorResult;

type QueryParam = string | number | boolean | string[] | undefined;
export type QueryParams = Record<string, QueryParam>;

interface BuildOptions extends RequestInit {
  auth?: boolean;
}

interface SuccessResult<T> {
  success: true;
  // body: { data: T };
  body: any; // dirty hook until better days come
}

interface ErrorResult {
  success: false;
  errorCode?: ErrorCodes;
}

// type BodyParam = string | number | boolean | number[][] | undefined | { [key: string]: ParamList };
type Request = (url: string, queryParams?: QueryParams, auth?: boolean) => ReturnType<typeof performRequest>;
type PostRequest = (
  url: string,
  body?: { [key: string]: any },
  queryParams?: QueryParams,
  auth?: boolean,
) => ReturnType<typeof performRequest>;
