import React, { FC } from 'react';
import { Card, Input, Button, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo.jpg';
import styles from './login.module.css';
import { LoginProps } from '.';
import { Navigate } from 'react-router-dom';
import {paths} from '../../constants/paths'


//@ts-ignore
export const LoginPage: FC<LoginProps> = props => {

  const handleFinish = (values: Store): void => {
    props.login(values.username, values.password, 'Неверный логин или пароль');
  };
  
  return (
<>
{props.isLoggedIn && <Navigate to={'/'} />}
        <div className={styles.wrapper}>
          <div className={styles.thumb}>
            <img src={logo} className={styles.logo} alt="logo" />
            <Card
              title={<span className={styles.head}>PizzaTruck</span>}
              className={styles.form}
              headStyle={{ borderBottom: 'none' }}>
              <Form onFinish={handleFinish} layout="vertical">
                <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                  <Input
                    prefix={<UserOutlined className={styles.icon} />}
                    className={styles.input}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                  <Input
                    prefix={<LockOutlined className={styles.icon} />}
                    type="password"
                    className={styles.input}
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item className={styles.bottom}>
                  <Button type="primary" htmlType="submit" size="large" className={styles.submit}>
                  Войти
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      
      </>
  )
};
