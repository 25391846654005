import React from 'react';
import {InputNumber} from 'antd'

type AmountInputProps = {
    defaultValue?: number | undefined;
    onChange: (value: number | null)=> void;
}
export const AmountInput = ({defaultValue, onChange} : AmountInputProps) => {

    return (
            <InputNumber value={defaultValue} size="large" onChange={onChange} placeholder={'0'}/>
    )
}