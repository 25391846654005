import React, { useCallback } from 'react';
import { Table as AntTable } from 'antd';
import { TableProps as AntTableProps } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { isEqual } from 'lodash-es';

const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '20', '50', '100', '200'];

type TableProps<T> = Omit<AntTableProps<T>, 'pagination'> & {
  pagination?:
    | false
    | (Omit<TablePaginationConfig, 'onChange'> & {
        pageSize: number;
        onChange: (page: number, pageSize: number) => void;
      });
  sort?: TableSort;
  onSortChange?: (sort: TableSort) => void;
};

export type TableSortOrder = 'ascend' | 'descend';
export type TableSort = { field: string; order: TableSortOrder }[];

export const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const { pagination, sort, onSortChange, ...restProps } = props;

  const handleChange = useCallback<NonNullable<TableProps<T>['onChange']>>(
    (pagination, filter, sorting) => {
      if (onSortChange) {
        const newSort = (Array.isArray(sorting) ? sorting : [sorting])
          .filter(s => !!s.order)
          .map(({ field, order }) => ({ field, order })) as TableSort;

        if (!isEqual(newSort, sort)) {
          onSortChange(newSort);
        }
      }
    },
    [sort],
  );

  const newPagination = pagination && {
    showSizeChanger: true,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    ...props.pagination,
    current: pagination?.current !== undefined ? pagination.current + 1 : pagination?.current,
    onShowSizeChange: (current: number, size: number): void => pagination?.onShowSizeChange?.(current - 1, size),
    onChange: (page: number, pageSize?: number): void =>
      pagination?.onChange(page - 1, pageSize || pagination.pageSize),
  };

  return (
    <AntTable<T>
      size="middle"
      sortDirections={['descend', 'ascend']}
      scroll={{ x: 'auto' }}
      {...restProps}
      pagination={newPagination}
      onChange={handleChange}
    />
  );
};
