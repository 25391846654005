// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".total_total__GXQlq{\n    display: flex;\n    margin: 2rem 0rem;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.total_total__title__EFAS7{\n    font-weight: 600;\n    margin-right: 1rem;\n}\n\n.total_mixedAmount__container__rwC\\+w{\ndisplay: flex;\nflex-direction: column;\ngap:1rem;   \n width: 14rem;\n}\n\n.total_mixedAmountInput__8nPIs{\nwidth: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.total_button__T-lbW{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Total/total.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;AACA,aAAa;AACb,sBAAsB;AACtB,QAAQ;CACP,YAAY;AACb;;AAEA;AACA,WAAW;IACP,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".total{\n    display: flex;\n    margin: 2rem 0rem;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.total__title{\n    font-weight: 600;\n    margin-right: 1rem;\n}\n\n.mixedAmount__container{\ndisplay: flex;\nflex-direction: column;\ngap:1rem;   \n width: 14rem;\n}\n\n.mixedAmountInput{\nwidth: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": "total_total__GXQlq",
	"total__title": "total_total__title__EFAS7",
	"mixedAmount__container": "total_mixedAmount__container__rwC+w",
	"mixedAmountInput": "total_mixedAmountInput__8nPIs",
	"button": "total_button__T-lbW"
};
export default ___CSS_LOADER_EXPORT___;
