import React, { ReactElement, useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TableSort } from '../../components/Table';
import { renderDateTime } from '../../components/FormattedDate';
import { ReportOrder } from '../../services/api/reports';


type UseColumnsProps = {
  sort: TableSort;
};

export const useColumns = (): ColumnProps<ReportOrder>[] => {

  return useMemo(() => {
    const columns: ColumnProps<ReportOrder>[] = [
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Категория',
        dataIndex: 'category_name',
        key: 'category_name',
      },
      {
        title: 'Позиция',
        dataIndex: 'item_name',
        key: 'item_name',
      },
      {
        title: 'Количество',
        dataIndex: 'item_count',
        key: 'item_count',
      },
      {
        title: 'Точка',
        dataIndex: 'point_name',
        key: 'point_name',
      },
      
    ];

    return columns.map<ColumnProps<ReportOrder>>(column => ({
      ...column,
    }));
  }, []);
};
