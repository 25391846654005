import { getRequest, ResponsePromise } from '../../requests/request';
import { REPORTS } from '../urls';

export type ReportFilterParams = {
    from?: string | null;
    to?: string | null;
};

type GetReportsParams = {
    filter?: ReportFilterParams;
}

//@ts-ignore
    export const getReports = async ({filter}: GetReportsParams): ResponsePromise<{report: Report}> =>{
        return getRequest(`${REPORTS}?${new URLSearchParams({from: filter?.from ?? '', to: filter?.to ??''})}`)
    }

    export type Report = {
        money: ReportMoney[],
        orders: ReportOrder[]
    }
    export type ReportOrder ={
        category_id: number;
        category_name: string;
        date: string;
        item_count: number;
        item_name: string;
        point_id: number;
        point_name: string;
    }
    export type ReportMoney = {
        card: number
        cash: number,
        date: string;
        money: number
    }