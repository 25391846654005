export const getItemAmountByQuantiny = (items: string[]):Item[]=>{
    //@ts-ignore
    const itemQuantity:Reacord<string, number> = items.reduce((acc, current) => (acc[current] = acc[current] + 1 || 1, acc), {});
    return Object.keys(itemQuantity).map(item=> {
        return {name: item.split('+')[0], quantity: itemQuantity[item], id: item.split('+')[1] }}
    )
}


export type Item = {
    name: string, 
    quantity: number,
    id: string;
}