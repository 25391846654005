
export enum Status {
  initial,
  loading,
  success,
  failure,
}

export type Pagination = {
  page: number;
  size: number;
};

export type ContentSize = {
  size: number;
  totalPages: number;
  totalElements: number;
};

export type PaginatedContent<T> = {
  content: T[];
} & ContentSize;

export type LocalizedString = Record<string | 'default', string>;
