import { message } from 'antd';
import { ErrorCodes, RequestException } from '../../requests/exception';
import { doLogin, doLogout } from '../../services/api/login';
import { getUser, User } from '../../services/api/user';
import { Thunk, OnError, wrapThunk } from '../../services/wrapThunk';
import { getUserActions, resetAction } from '../../state/rootActions';
import { initializeAppActions, loginActions, logoutActions } from './actions';

export const initializeAppThunk = (user?: User): Thunk => {
  const thunk: Thunk = async dispatch => {
    dispatch(initializeAppActions.request());

    if (!user) {
      const response = await getUser();

      if (response.success) {
        user = response.body.user as User;
        dispatch(loginActions.success());
        dispatch(getUserActions.success(user));
      } else {
        dispatch(initializeAppActions.failure());

        return;
      }
    }

    dispatch(initializeAppActions.success());
  };

  const errorCallback: OnError = async dispatch => {
    dispatch(initializeAppActions.failure());
  };

  return wrapThunk(thunk, errorCallback);
};

export const loginThunk = (username: string, password: string, errorMessage: string): Thunk => {
  const thunk: Thunk = async dispatch => {
    dispatch(loginActions.request());

    const response = await doLogin(username, password);

    if (response.success) {
      sessionStorage.setItem('token', response.body.token)
      window.location.reload()
      const user = response.body as User;
      dispatch(loginActions.success());
      dispatch(getUserActions.success(user));
      dispatch(initializeAppThunk(user));
    } else {
      dispatch(loginActions.failure());
    }
  };

  const errorCallback: OnError = async (dispatch, error) => {
    dispatch(loginActions.failure());
    if (error instanceof RequestException && error.code === ErrorCodes.UNAUTHORIZED) {
      message.error(errorMessage, 5);
    }
  };

  return wrapThunk(thunk, errorCallback);
};

export const logoutThunk = (): Thunk => {
  const thunk: Thunk = async dispatch => {
    dispatch(logoutActions.request());

    const response = await doLogout();

    if (response.success) {
      sessionStorage.setItem('token', '')
      dispatch(resetAction());
      dispatch(logoutActions.success());
    } else {
      dispatch(logoutActions.failure());
    }
  };

  const errorCallback: OnError = async dispatch => {
    dispatch(logoutActions.failure());
  };

  return wrapThunk(thunk, errorCallback);
};
