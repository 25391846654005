// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".moneyInput_money__UFW0J{\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n}\n\n.moneyInput_buttonsContainer__gTcWI{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n    align-items: center;\n}\n\n.moneyInput_button__KFoyu{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}\n\n@media (max-width: 576px) {\n    .moneyInput_money__UFW0J{\n        flex-direction: column;\n        align-items: baseline;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/MoneyInput/moneyInput.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;QACtB,qBAAqB;IACzB;AACJ","sourcesContent":[".money{\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n}\n\n.buttonsContainer{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n    align-items: center;\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}\n\n@media (max-width: 576px) {\n    .money{\n        flex-direction: column;\n        align-items: baseline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"money": "moneyInput_money__UFW0J",
	"buttonsContainer": "moneyInput_buttonsContainer__gTcWI",
	"button": "moneyInput_button__KFoyu"
};
export default ___CSS_LOADER_EXPORT___;
