import { useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import {TotalItem} from '../../services/api/workingDay'


export const useColumns = (): ColumnProps<TotalItem>[] => {


  return useMemo(() => {
    const columns: ColumnProps<TotalItem>[] = [
      {
        title: 'Категория',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Товар',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Количество',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: 'Сумма',
        dataIndex: 'cost',
        key: 'cost',
        render: (record)=> <span>{`${Math.floor(record)}руб`}</span>
      },
    ];

    return columns;
  }, []);
};
