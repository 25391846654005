// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateRange_container__GkXS8 {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: 15px;\n}\n\n@media (max-width: 768px) {\n  .dateRange_container__GkXS8 {\n    margin-bottom: 1rem;\n  }\n}\n\n.dateRange_dateSize__HYLMM {\n  max-height: 2rem;\n}\n\n@media (max-width: 768px) {\n  .dateRange_marginBottom__EBJlY {\n    margin-bottom: 1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/DateRange/dateRange.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: 15px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    margin-bottom: 1rem;\n  }\n}\n\n.dateSize {\n  max-height: 2rem;\n}\n\n@media (max-width: 768px) {\n  .marginBottom {\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dateRange_container__GkXS8",
	"dateSize": "dateRange_dateSize__HYLMM",
	"marginBottom": "dateRange_marginBottom__EBJlY"
};
export default ___CSS_LOADER_EXPORT___;
