import { AmountInput } from '../AmountInput';
import styles from './newDay.module.css';
import { Button, Select } from 'antd';

type NewDayProps = {
    optionPoints?: { value: number, label: string }[],
    handleOpenWorkingDay: () => void;
    handleSelectPoint: (value: number) => void;
    handleDoughAmountChange: (value: number | null)=> void;
    workingPoint: number;
    doughAmount: number | null;
}

export const NewDay = ({ optionPoints, handleOpenWorkingDay, handleSelectPoint, handleDoughAmountChange, workingPoint, doughAmount }: NewDayProps) => {
    return <div className={styles.new}>
        {optionPoints && (
            <div className={styles.point}>
                <span>Рабочая точка</span>
                <Select
                size='large'
                    defaultValue={optionPoints[0].value}
                    style={{ width: 120 }}
                    onChange={handleSelectPoint}
                    options={optionPoints}
                />
            </div>
        )}
        <div className={styles.point}>
        <span>Количество теста</span>
        <AmountInput onChange={handleDoughAmountChange}/></div>
        <Button disabled={!workingPoint || !doughAmount } className={styles.button} onClick={handleOpenWorkingDay} type="primary">
            Начать смену
        </Button>
    </div>
}