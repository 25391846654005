import { createAsyncAction } from 'typesafe-actions';

export const initializeAppActions = createAsyncAction(
  'REQ_INITIALIZE_APP',
  'SUCCESS_INITIALIZE_APP',
  'FAILURE_INITIALIZE_APP',
)<undefined, undefined, undefined>();

export const logoutActions = createAsyncAction('REQ_LOGOUT', 'SUCCESS_LOGOUT', 'FAILURE_LOGOUT')<
  undefined,
  undefined,
  undefined
>();

export const loginActions = createAsyncAction('REQ_LOGIN', 'SUCCESS_LOGIN', 'FAILURE_LOGIN')<
  undefined,
  undefined,
  undefined
>();
