import React, {useState, useEffect } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Card, Button } from 'antd';
import styles from './itemCard.module.css'

type ItemCardProps = {
    name: string,
    price: number;
    itemKey: number,
    id: number,
    handleItemMoneyAmountChange: (quantity: number, price: number, name: string, id: number) => void;
    itemQuantity: number;
}
export const ItemCard = ({ name, price, itemKey, handleItemMoneyAmountChange,id, itemQuantity }: ItemCardProps) => {
    const [newItemQuantity, setItemQuantity] = useState(itemQuantity);

    const handleQuantityChange = (quantity: number) => {
        const newQuantity = newItemQuantity + quantity;
        if (newQuantity > -1) {
            handleItemMoneyAmountChange(quantity, price, name, id)
            const newQuantity = newItemQuantity + quantity;
            setItemQuantity(newQuantity)
        }
    }


    const { Meta } = Card;
    return <Card key={itemKey}
            className={newItemQuantity > 0 ? [styles.card, styles.selected].join(' ') : styles.card}
            actions={[
                <Button className={styles.cardButton} type="text" icon={<MinusOutlined />} size="large" onClick={() => handleQuantityChange(-1)} />,
                <div className={styles.amount}>{newItemQuantity}</div>,
                <Button className={styles.cardButton} type="text" icon={<PlusOutlined />} size="large" onClick={() => handleQuantityChange(1)} />
            ]}
        >
            <Meta
            className={styles.cardMeta}
                title={name}
                description={price}
            />
        </Card> 
}