import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { app, AppState } from './app/reducer';
import { resetAction } from './rootActions';
import { user, UserState } from './user/reducer';

export type RootState = {
  router: RouterState;
  app: AppState;
  user: UserState;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const appReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app,
    user,
  });

export const rootReducer = (history: History) => (state: RootState | undefined, action: AnyAction) => {
  const resetState = action.type === resetAction().type;

  return appReducer(history)((resetState ? { router: state?.router } : state) as RootState, action);
};
