// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createOrder_buttonRowRight__container__xYx84{\n    display: flex;\n    justify-content: right;\n    margin-bottom: 1rem;\n    gap: 1rem\n}\n\n.createOrder_button__UKxAt{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}\n\n.createOrder_order__5YOYl{\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    margin-top: 1rem;\n    width: -moz-fit-content;\n    width: fit-content;\n}\n.createOrder_red__eDSbW{\n    background-color: #ff7f4f;\n}\n\n\n.createOrder_orderItem__NjU0H{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n}\n\n.createOrder_items__container__9DilK{\n    display: flex;\n    gap: 1rem;\n    flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/components/CreateOrder/createOrder.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB;AACJ;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB","sourcesContent":[".buttonRowRight__container{\n    display: flex;\n    justify-content: right;\n    margin-bottom: 1rem;\n    gap: 1rem\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}\n\n.order{\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    margin-top: 1rem;\n    width: fit-content;\n}\n.red{\n    background-color: #ff7f4f;\n}\n\n\n.orderItem{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n}\n\n.items__container{\n    display: flex;\n    gap: 1rem;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonRowRight__container": "createOrder_buttonRowRight__container__xYx84",
	"button": "createOrder_button__UKxAt",
	"order": "createOrder_order__5YOYl",
	"red": "createOrder_red__eDSbW",
	"orderItem": "createOrder_orderItem__NjU0H",
	"items__container": "createOrder_items__container__9DilK"
};
export default ___CSS_LOADER_EXPORT___;
