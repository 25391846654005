import React from 'react';
import { Button, Card, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

type TableCardProps = {
  loading: boolean;
  count?: number;
  children: React.ReactNode;
  onRefresh?: () => void;
  customTitle?: React.ReactNode;
};

export const TableCard = ({ loading, count, children, onRefresh, customTitle }: TableCardProps) => {
  return (
    <Card
      bordered={false}
      size="small"
      title={
        !loading ? (
          <>
          {customTitle}
          </>
        ) : (
          <Spin size="small" />
        )
      }
      extra={
        <>
          {onRefresh && (
            <Button shape="circle" type="text" icon={<ReloadOutlined />} disabled={loading} onClick={onRefresh} />
          )}
        </>
      }>
      {children}
    </Card>
  );
};
