import React, { useState, useCallback, useEffect, useMemo } from "react";
import styles from "./order.module.css";
import { Button } from "antd";
import {Link} from 'react-router-dom'
import {
    showErrorNotification,
    showSuccessNotification,
} from "../../utils/notification";
import { CreateOrder } from "../../components/CreateOrder";
import { CloseWorkingDay } from "../../components/CloseWorkingDay";
import { NewDay } from '../../components/NewDay';
import { closeDay } from '../../services/api/workingDay'
import { useGetPoints } from "../../services/hooks/useGetPoints";
import { useWorkingDayOpened } from '../../hooks/useWorkingDayOpened';
import { Point } from '../../services/api/points'
import { LoadingSpin } from "../../components/LoadingSpin";
import { Status } from '../../types';
import { useGetUserId } from '../../hooks/useGetUserId';
import { useLogout } from '../../services/hooks/useLogout'
import { Item } from './utils'
import { ActiveOrder } from "../../components/ActiveOrder";
import { useGetActiveOrders } from '../../services/hooks/useGetActiveOrders'
import { useGetWorkingDayId } from '../../hooks/useGetWorkingDayId';
import { useCloseOrder } from '../../services/hooks/useCloseOrder';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import {useGetIsUserAdmin} from '../../hooks/useGetIsUserAdmin'
import { useOpenDay } from '../../services/hooks/useOpenDay';


export function Order(): React.ReactElement {
    const [points, statusPointsFetching, getPoints] = useGetPoints();
    const [, statusClosingOrderFetching, closeOrder] = useCloseOrder();
    const [activeOrders, statusActiveOrdersFetching, getActiveOrders] = useGetActiveOrders();
    const [currentOrderId, setCurrentOrderId] = useState<number | undefined>()
    const [openedDay, statusOpenDayFetching, openDay] = useOpenDay();
    const [workingDayId, setWorkingDayId] = useState<number>(useGetWorkingDayId())
    const [isWorkingDay, setWorkingDay] = useState(false);
    const [doughAmount, setDoughAmount] = useState<number | null>(0)
    const [workingPoint, setWorkingPoint] = useState<number>(1)
    const [isWorkingDayClosing, setWorkingDayClosing] = useState(false);
    const [isOrderInProgress, setOrderInProgress] = useState(false);

    const [freeAmount, setFreeAmount] = useState<null | number>(null)
    const userId = useGetUserId();
    const isUserWorkingDayOpened = useWorkingDayOpened();
    const isLoggedIn = useIsLoggedIn();
    const isAdmin = useGetIsUserAdmin()
    const logout = useLogout();
    const isPointsLoading = statusPointsFetching === Status.loading;
    const isActiveOrdersLoading = statusActiveOrdersFetching === Status.loading;
    const isClosingOrdersLoading = statusClosingOrderFetching === Status.loading;
    const isDayOpenLoading = statusOpenDayFetching === Status.loading;


    const getActiveOrdersCallBack = () => {
        setTimeout(() => getActiveOrders(workingDayId), 1000)
    }

    useEffect(() => {
        getPoints();
    }, []);

    useEffect(() => {
        setWorkingDay(isUserWorkingDayOpened);
    }, [isUserWorkingDayOpened])

    useEffect(() => {
        points && setWorkingPoint(points?.points[0].id)
    }, [points])


    const handleOpenWorkingDay = useCallback(async () => {
        try {
            await openDay(userId, workingPoint, doughAmount)
            showSuccessNotification("Смена успешно начата");
            setWorkingDay(true);
        } catch (e) {
            showErrorNotification("Ошибка начала смены");
        }
    }, [userId, workingPoint, doughAmount]);

    const handleCloseWorkingDay = useCallback(async (freeAmount: number | null, doughAmount: number | null) => {
        try {
            await closeDay(userId, workingPoint, freeAmount, doughAmount)
            showSuccessNotification("Смена успешно закрыта");
            setWorkingDay(false);
            setWorkingDayClosing(false);
            setDoughAmount(0)
        } catch (e) {
            showErrorNotification("Ошибка закрытия смены");
        }
    }, []);

    const handleCancelCloseWorkingDay = () => {
        setWorkingDayClosing(false);
    }


    const handleSelectPoint = (value: number) => {
        setWorkingPoint(value)
    }

    const handleCloseOrder = useCallback(async (e: React.MouseEvent, orderId: number) => {
        e.stopPropagation();
        try {
            await closeOrder(orderId)
            showSuccessNotification("Заказ был закрыт успешно");
            getActiveOrders(workingDayId);
        } catch (e) {
            showErrorNotification("Ошибка закрытия заказа");
        }
    }, []);

    const handleActiveOrderClick = (id: number) => {
        setOrderInProgress(true);
        setCurrentOrderId(id)
    }

    useEffect(() => {
        if (openedDay) {
            const dayId = openedDay.working_day_id;
            if (!workingDayId && dayId) setWorkingDayId(dayId)
        }
    }, [openedDay])

    useEffect(() => {
        if (isWorkingDay) {
            workingDayId && getActiveOrders(workingDayId)
        }
    }, [isWorkingDay, workingDayId]);


    const optionPoints = useMemo(
        () => points?.points.map((point: Point) => ({ value: point.id, label: point.name })), [points])


    if (isWorkingDayClosing)
        return (
            <CloseWorkingDay
                freeAmount={freeAmount}
                workingDayId={workingDayId}
                handleFreeAmountChanged={setFreeAmount}
                handleCloseWorkingDay={handleCloseWorkingDay}
                handleCancelCloseWorkingDay={handleCancelCloseWorkingDay}
                handleDoughAmountChange={setDoughAmount}
                doughAmount={doughAmount}
            />
        );

    if (isPointsLoading || !workingPoint || isDayOpenLoading) return <LoadingSpin />

    return (
        <div className={styles.root}>
            <div className={styles.actions}>

                {isWorkingDay && (
                    <div className={styles.closeWorkingDay}>
                        <Button className={styles.button} onClick={() => setWorkingDayClosing(true)} type="primary">
                            Закрыть Смену
                        </Button>
                    </div>
                )}
                {isLoggedIn && <div className={styles.buttonRowRight__container}>
                    <Button className={styles.button} onClick={logout} type="primary">
                        Выйти
                    </Button>
                    {isAdmin && <Link to={'/reports'} className={styles.link__button}>
                        Отчеты
                    </Link>}
                </div>}
            </div>
            {!isWorkingDay ? (
                <NewDay optionPoints={optionPoints} handleOpenWorkingDay={handleOpenWorkingDay} handleSelectPoint={handleSelectPoint} handleDoughAmountChange={setDoughAmount} workingPoint={workingPoint} doughAmount={doughAmount} />
            ) : !isOrderInProgress ? (
                <div>
                    {!isActiveOrdersLoading ?
                        <div className={styles.orders__container}>
                            {isClosingOrdersLoading ? <LoadingSpin relative /> :
                                activeOrders?.working_day.orders?.map((order, index) => <ActiveOrder key={index} items={order.items} total={order.discounted_total} id={order.id} handleCloseOrder={handleCloseOrder} handleEditOrder={handleActiveOrderClick} />
                                ).reverse()}
                        </div> :
                        <LoadingSpin />}
                    <div className={styles.new}>
                        <Button className={styles.button} onClick={() => setOrderInProgress(true)} type="primary">
                            Новый заказ
                        </Button>
                    </div>
                </div>
            ) : (
                <CreateOrder
                    currentOrderId={currentOrderId}
                    setCurrentOrderId={setCurrentOrderId}
                    workingDayId={workingDayId}
                    getActiveOrdersCallBack={getActiveOrdersCallBack}
                    isOrderInProgress={isOrderInProgress}
                    setOrderInProgress={setOrderInProgress}
                    workingPoint={workingPoint}
                />
            )}
        </div>
    );
}
