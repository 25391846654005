import {RootRouter} from './routes'
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { history, configureStore } from './state/store';
import Auth from './components/Auth'
import {createMemoryHistory} from 'history'
import {BrowserRouter} from 'react-router-dom'


const store = configureStore();

export default function App() {
  const history = createMemoryHistory();
  return (
    //@ts-ignore
    <Provider store={store}><Auth>
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </Auth>
  </Provider>
  );
}
