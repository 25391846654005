import styles from './activeOrder.module.css';
import { OrderItemName } from '../../services/api/workingDay'
import { Button } from 'antd';


type ActiveOrderProps = {
    items: OrderItemName[],
    total: number;
    id: number;
    handleCloseOrder: (e:React.MouseEvent, orderId: number) => void;
    handleEditOrder: (id: number) => void;
}
export const ActiveOrder = ({ items, total, id, handleCloseOrder, handleEditOrder }: ActiveOrderProps) => {

    const handleOrderClick = () => {
        handleEditOrder(id)
    }

    return (
        <div className={styles.order__container} onClick={handleOrderClick}>

            <div>
                {items.map((item, index) => {
                    return <div className={styles.item} key={index}>
                        <span>{`${item.name}`}</span>
                        <span>{`${item.count}`}</span>
                    </div>
                })}</div>
            <div><span className={styles.summary}>{`Итого: ${Math.floor(total)}руб`}</span>
                <div className={styles.close}><Button className={styles.button} onClick={(e) => handleCloseOrder(e, id)}>Закрыть</Button></div></div>
        </div>
    )
}