// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".itemCard_card__ERmdp{\n    width: 270px;\n}\n\n.itemCard_selected__RJ0o2{\nborder: 1px solid #1677ff;\n}\n\n.itemCard_cardButton__s1rcV{\nwidth: 5rem!important;\nheight: 4rem!important;\n}\n\n.itemCard_amount__Q4oxc{\n    height: 4rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n@media (max-width: 576px) {\n    .itemCard_card__ERmdp{\n        width: 100%;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ItemCard/itemCard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;AACA,yBAAyB;AACzB;;AAEA;AACA,qBAAqB;AACrB,sBAAsB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".card{\n    width: 270px;\n}\n\n.selected{\nborder: 1px solid #1677ff;\n}\n\n.cardButton{\nwidth: 5rem!important;\nheight: 4rem!important;\n}\n\n.amount{\n    height: 4rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n@media (max-width: 576px) {\n    .card{\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "itemCard_card__ERmdp",
	"selected": "itemCard_selected__RJ0o2",
	"cardButton": "itemCard_cardButton__s1rcV",
	"amount": "itemCard_amount__Q4oxc"
};
export default ___CSS_LOADER_EXPORT___;
