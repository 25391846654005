import { useMemo } from 'react'
import { Button, RadioChangeEvent } from 'antd';
import { MoneyInput } from '../MoneyInput';
import { PaymentType } from '../PaymentType';
import { Item } from '../../pages/Order/utils'
import { AmountInput } from '../AmountInput';
import { isEmpty } from 'lodash';

import styles from './total.module.css'

type TotalProps = {
    isNewOrder: boolean;
    workingDayId: number;
    totalAmount: number;
    totalAmountWithDiscount: number;
    isDiscountApplied: boolean;
    discount: number | null;
    totalAmountByPositionWithQuantity?: Item[];
    setDiscount: (value: number | null) => void;
    handlePaymentTypeChanged: (e: RadioChangeEvent) => void;
    paymentType: 'cash' | 'card' | 'mix';
    handleSendOrder: (discount: number | null, paymentType: 'cash' | 'card' | 'mix', totalAmountWithDiscount: number, totalAmountByPositionWithQuantity?: Item[], mixedAmounCard?: number | null, mixedAmounCash?: number | null, totalAmount?: number) => void;
    handleUpdateOrder: (discount: number | null, paymentType: 'cash' | 'card' | 'mix', totalAmountWithDiscount: number, totalAmountByPositionWithQuantity?: Item[], mixedAmounCard?: number | null, mixedAmounCash?: number | null, totalAmount?: number) => void;
    handleApplyDiscount: () => void;
    handleCancelDiscount: () => void;
    handleMixedAmountCashChanged: (value: number | null) => void;
    handleMixedAmountCardChanged: (value: number | null) => void;
    mixedAmounCash: number | null;
    mixedAmounCard: number | null;
    isSendingOrder: boolean;
}
export const Total = ({ isNewOrder, totalAmountWithDiscount, isDiscountApplied, discount,
    setDiscount, handlePaymentTypeChanged, paymentType, handleSendOrder, handleUpdateOrder, handleApplyDiscount, handleCancelDiscount, totalAmountByPositionWithQuantity,
    handleMixedAmountCashChanged, handleMixedAmountCardChanged, mixedAmounCash, mixedAmounCard, isSendingOrder, totalAmount }: TotalProps) => {

        const handleSendButtonClick = () => {
            isNewOrder ?  
            handleSendOrder(discount, paymentType, totalAmountWithDiscount, totalAmountByPositionWithQuantity, mixedAmounCard, mixedAmounCash, totalAmount)
            :
            handleUpdateOrder(discount, paymentType, totalAmountWithDiscount, totalAmountByPositionWithQuantity, mixedAmounCard, mixedAmounCash, totalAmount)
        }
    const isOrderEmpty = isEmpty(totalAmountByPositionWithQuantity);

    const mixedAmount = useMemo(() => {
        const mixedAmount = (mixedAmounCash ?? 0) + (mixedAmounCard ?? 0);
        return mixedAmount
    }, [mixedAmounCash, mixedAmounCard])

    const isAmountMatch = useMemo(() => {

        return mixedAmount === totalAmountWithDiscount;
    }, [mixedAmount, totalAmountWithDiscount,])

    return (<div className={styles.total}>
        <div>
            <span className={styles.total__title}>Итого:</span>
            <span>{`${Math.floor(totalAmountWithDiscount)}руб`}</span>
        </div>
        <MoneyInput isAmountApplied={isDiscountApplied} amount={discount} onChange={setDiscount}
            handleApplyAmount={handleApplyDiscount} handleCancelAmount={handleCancelDiscount} title={'Скидка, %'} />
        <PaymentType onChange={handlePaymentTypeChanged} paymentType={paymentType} />
        {paymentType === 'mix' && (
            <>
                <div className={styles.mixedAmount__container}>
                    <div className={styles.mixedAmountInput}>
                        <span>Наличные:</span>
                        <AmountInput defaultValue={mixedAmounCash ?? undefined} onChange={handleMixedAmountCashChanged} />
                    </div>
                    <div className={styles.mixedAmountInput}>
                        <span>Карта:</span>
                        <AmountInput defaultValue={mixedAmounCard ?? undefined} onChange={handleMixedAmountCardChanged} />
                    </div>
                </div>
                {!isAmountMatch && (
                    <span>Итоговая и введенные суммы не совпадают</span>
                )}
            </>
        )}
        <div>
            <Button className={styles.button} disabled={isOrderEmpty || (paymentType === 'mix' && !isAmountMatch) || isSendingOrder} onClick={handleSendButtonClick} type='primary' >
                {isNewOrder ? 'Отправить' : 'Обновить'}
            </Button>
        </div>
    </div>
    )
}