import { connect } from 'react-redux';
import { User } from '../services/api/user';
import { RootState } from 'typesafe-actions';
import { RootRouterComponent } from './RootRouter';

const mapStateToProps = ({ app, user }: RootState): RootRouterState => ({
  isLoggedIn: app.isLoggedIn,
  user: user?.data,
});

export const RootRouter = connect(mapStateToProps)(RootRouterComponent);

interface RootRouterState {
  isLoggedIn: boolean;
  user: User;
}

export type RootRouterProps = RootRouterState;
