import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

export function useWorkingDayOpened(): boolean {
  return useSelector((state: RootState) => {
    const activeWorkingDay = state.user.data.active_working_day

    return !!activeWorkingDay;
  });
}
