import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

export function useGetIsUserAdmin(): boolean {
  return useSelector((state: RootState) => {
    const isAdmin = state.user.data.role === 'Admin'

    return isAdmin;
  });
}
