import {Link} from 'react-router-dom'
export function ErrorPage() {

  return (
    <div>
      <h2>Здесь ничего нет!</h2>
      <p>
        <Link to="/">На главную страницу</Link>
      </p>
    </div>
  );
}