// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newDay_new__2gDLd {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-top: 2rem;\n    align-items: center;\n    gap:1rem;\n}\n\n.newDay_point__W9Pji{\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    align-items: center;\n}\n\n.newDay_button__BV5ke{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/NewDay/newDay.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".new {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-top: 2rem;\n    align-items: center;\n    gap:1rem;\n}\n\n.point{\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    align-items: center;\n}\n\n.button{\n    font-size: 1.5rem;\n    padding: 0.7rem;\n    width: auto;\n    height: auto;\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "newDay_new__2gDLd",
	"point": "newDay_point__W9Pji",
	"button": "newDay_button__BV5ke"
};
export default ___CSS_LOADER_EXPORT___;
