import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

export function useIsLoggedIn(): boolean {
  return useSelector((state: RootState) => {
    const isLoggedIn = state.app.isLoggedIn;

    return isLoggedIn;
  });
}
