import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logoutThunk } from '../../state/app/thunk';

export const useLogout = (): (() => void) => {
  const dispatch = useDispatch();

  const logout = useCallback(async () => {
    //@ts-ignore
    await dispatch(logoutThunk());

    window.location.reload();
  }, [dispatch]);

  return logout;
};
