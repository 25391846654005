import { connect } from 'react-redux';
import { initializeAppThunk } from '../../state/app/thunk';
import { Status } from '../../types';
import { RootState } from 'typesafe-actions';
import { Auth } from './Auth';

export type MapStateToProps = {
  appStatus: Status;
};

export type MapDispatchToProps = {
  initializeApp: () => void;
};

const mapStateToProps = ({ app }: RootState): MapStateToProps => ({
  appStatus: app.status,
});

const mapDispatchToProps: MapDispatchToProps = {
  initializeApp: initializeAppThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
