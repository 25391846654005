import React, { useEffect, useState, useCallback } from 'react';
import styles from './createOrder.module.css';
import { Button, Tabs, RadioChangeEvent } from 'antd';
import { Total } from '../Total';
import { OrderItemName } from '../../services/api/workingDay';
import { useGetOrder } from '../../services/hooks/useGetOrder';
import { Status } from '../../types';
import { LoadingSpin } from '../LoadingSpin';
import { useGetItems } from '../../services/hooks/useGetItems';
import { useGetCategories } from '../../services/hooks/useGetCategories';
import { useSendOrder } from '../../services/hooks/useSendOrder';
import { useUpdateOrder } from '../../services/hooks/useUpdateOrder';
import { useRemoveOrder } from '../../services/hooks/useRemoveOrder'
import { getItemAmountByQuantiny, Item } from '../../pages/Order/utils';
import {
    showErrorNotification,
    showSuccessNotification,
} from "../../utils/notification";
import { OrderItem } from '../../services/api/orders';
import { ItemCard } from '../ItemCard';
import { isEmpty } from 'lodash'

const getAmountWithDiscount = (amount: number, discount:number | null) =>{

    if (discount && discount> 0){
        const percentDiscount = 1 - discount / 100;
        const totalWithDiscount = amount * percentDiscount;
        return Math.floor(totalWithDiscount);
    }
    return amount
}

type CreateOrderProps = {
    currentOrderId: number | undefined;
    setCurrentOrderId: (id: number | undefined) => void;
    getActiveOrdersCallBack: () => void;
    workingDayId: number;
    isOrderInProgress: boolean;
    setOrderInProgress: (flag: boolean)=> void;
    workingPoint: number;

}
export const CreateOrder = ({ currentOrderId, getActiveOrdersCallBack, workingDayId, isOrderInProgress, setOrderInProgress, setCurrentOrderId, workingPoint }: CreateOrderProps) => {

    const [menuItems, statusItemsFetching, getItems] = useGetItems();
    const [categories, statusCategoriesFetching, getCategories] = useGetCategories();
    const [, statusSendingOrder, sendOrder] = useSendOrder();
    const [, statusUpdatingOrder, updateOrder] = useUpdateOrder();
    const [, , removeOrder] = useRemoveOrder();
    const [currentOrderItems, setCurrentOrderItems] = useState<OrderItemName[]>([])
    const [activeTab, setActiveTab] = useState(categories ? `${categories.categories[0].id}` : '1');
    const [discount, setDiscount] = useState<null | number>(null);
    const [isDiscountApplied, setDiscountApplied] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountByPosition, setTotalAmountByPosition] = useState<string[]>([]);
    const [totalAmountByPositionWithQuantity, setTotalAmountByPositionWithQuantity] = useState<Item[]>();
    const [totalAmountWithDiscount, setTotalAmountWithDiscount] = useState(0);
    const [mixedAmounCash, setMixedAmounCash] = useState<null | number>(0);
    const [mixedAmounCard, setMixedAmounCard] = useState<null | number>(0);
    const [paymentType, setPaymentType] = useState<"cash" | "card" | "mix">("cash");


    const [order, statusOrderFetching, getOrder] = useGetOrder();
    const isSendingOrder = statusSendingOrder === Status.loading;
    const isCurrentOrderExist = !!currentOrderId;

    useEffect(() => {
        getCategories();
        getItems(workingPoint);
    }, []);

    useEffect(() => {
        if (currentOrderId) {
            getOrder(currentOrderId)
        }
    }, [currentOrderId])

    useEffect(() => {
        if (order) {
            setPaymentType(order.order.payment_type)
            setDiscountApplied(!!order.order.discount);
            setDiscount(order.order.discount! * 100) ;
            setTotalAmount(order.order.total);
            setTotalAmountWithDiscount(order.order.discounted_total);

            if (order.order.payment_type === 'mix'){
                setMixedAmounCash(order.order.cash)
                setMixedAmounCard(order.order.card)
            }

            const totalAmountByPosition: string[] = []
            order.order_items?.map(item=> {
                return totalAmountByPosition.push(`${item.item_name}+${item.item_id}`);
            })
            setTotalAmountByPosition(totalAmountByPosition)
            const amountWithQuantity = getItemAmountByQuantiny(totalAmountByPosition);
            setTotalAmountByPositionWithQuantity(amountWithQuantity);
            //@ts-ignore
            const currentOrderTab = order.order_items[0].category_id;
            setActiveTab(`${currentOrderTab}`)
        }
    }, [order])

    useEffect(() => {
        if (!isEmpty(currentOrderItems)) {
            const currentOrderTab = currentOrderItems[0].category_id
            setActiveTab(`${currentOrderTab}`)
        }

    }, [currentOrderItems])

    const isOrderLoading = statusOrderFetching === Status.loading;
    const isCategoriesLoading = statusCategoriesFetching === Status.loading;
    const isItemsLoading = statusItemsFetching === Status.loading;

    const handleFinalAmountChanged = (quantity: number, price: number, name: string, id: number,) => {
        const amount = quantity * price;
        setTotalAmount(totalAmount + amount);
        setTotalAmountWithDiscount(totalAmount + amount);
        let newAmount
        if (quantity > 0) {
            newAmount = totalAmountByPosition.concat([`${name}+${id}`]);
        } else {
            const itemIndex = totalAmountByPosition.findIndex(position => position === `${name}+${id}`);
            totalAmountByPosition.splice(itemIndex, 1);
            newAmount = totalAmountByPosition
        }
        setTotalAmountByPosition(newAmount)
        setDiscount(0)
        setDiscountApplied(false)
        const amountWithQuantity = getItemAmountByQuantiny(newAmount);
        setTotalAmountByPositionWithQuantity(amountWithQuantity)
    };


    const handleApplyDiscount = () => {
        if (discount && discount > 0) {
            const amountWithDiscount = getAmountWithDiscount(totalAmount, discount)
            setTotalAmountWithDiscount(amountWithDiscount);
            setDiscountApplied(true);
        }
    };

    const handleCancelDiscount = () => {
        setTotalAmountWithDiscount(totalAmount);
        setDiscountApplied(false);
    };

    const handlePaymentTypeChanged = (e: RadioChangeEvent) => {
        const paymentType = e.target.value;
        setPaymentType(paymentType);
    };

    const handleSendOrder = useCallback(async (discount: number | null, paymentType: 'cash' | 'card' | 'mix',  totalAmountWithDiscount: number, totalAmountByPositionWithQuantity?: Item[], mixedAmounCard?: number | null, mixedAmounCash?: number | null, totalAmount?: number) => {

        const cashAmount = paymentType === 'cash' ? totalAmountWithDiscount : paymentType === 'mix' ? mixedAmounCash : 0;
        const cardAmount = paymentType === 'card' ? totalAmountWithDiscount : paymentType === 'mix' ? mixedAmounCard : 0;
        const orderItem: OrderItem[] | undefined = totalAmountByPositionWithQuantity?.map(item => {
            return { item_id: +item.id, count: item.quantity } as OrderItem
        })
        const order = {
            discount: discount ? discount / 100 : 0,
            payment_type: paymentType,
            order_items: orderItem,
            cash: cashAmount ?? 0,
            card: cardAmount ?? 0,
            total: totalAmount,
            discounted_total: totalAmountWithDiscount
        };
        try {
            await sendOrder(order)
            showSuccessNotification("Заказ был создан успешно");
            setOrderInProgress(false);
            setDiscountApplied(false);
            setDiscount(0)
            getActiveOrdersCallBack()
        } catch (e) {
            showErrorNotification("Ошибка создания заказа");
        }
    }, []);

    const handleUpdateOrder = useCallback(async (discount: number | null, paymentType: 'cash' | 'card' | 'mix', totalAmountWithDiscount: number, totalAmountByPositionWithQuantity?: Item[], mixedAmounCard?: number | null, mixedAmounCash?: number | null, totalAmount?: number) => {

        const cashAmount = paymentType === 'cash' ? totalAmountWithDiscount : paymentType === 'mix' ? mixedAmounCash : 0;
        const cardAmount = paymentType === 'card' ? totalAmountWithDiscount : paymentType === 'mix' ? mixedAmounCard : 0;
        const orderItem: OrderItem[] | undefined = totalAmountByPositionWithQuantity?.map(item => {
            return { item_id: +item.id, count: item.quantity } as OrderItem
        })
        const order = {
            discount: discount ? discount / 100 : 0,
            payment_type: paymentType,
            order_items: orderItem,
            cash: cashAmount ?? 0,
            card: cardAmount ?? 0,
            total: totalAmount,
            discounted_total: totalAmountWithDiscount
        };
        try {
            await updateOrder(order, currentOrderId!)
            showSuccessNotification("Заказ был обновлен успешно");
            setOrderInProgress(false);
            setDiscountApplied(false);
            setDiscount(0)
            getActiveOrdersCallBack()
        } catch (e) {
            showErrorNotification("Ошибка обновления заказа");
        }
    }, []);

    const handleCancelOrder = () => {
        setOrderInProgress(false);
        setDiscountApplied(false);
        setCurrentOrderItems([])
        setCurrentOrderId(undefined)
        setTotalAmount(0);
        setTotalAmountWithDiscount(0);
        setTotalAmountByPosition([])
        setTotalAmountByPositionWithQuantity([])
    }

    const handleRemoveOrder = useCallback(async (orderId: number) => {

        try {
            await removeOrder(orderId)
            showSuccessNotification("Заказ был удален успешно");
            setOrderInProgress(false);
            setDiscountApplied(false);
            setCurrentOrderItems([])
            setCurrentOrderId(undefined)
            setTotalAmount(0);
            setTotalAmountWithDiscount(0);
            setTotalAmountByPosition([])
            setTotalAmountByPositionWithQuantity([])
            getActiveOrdersCallBack()
        } catch (e) {
            showErrorNotification("Ошибка удаления заказа");
        }
    }, []);

    const amountItems = totalAmountByPositionWithQuantity?.map((item, index) => {
        return <div key={index} className={styles.orderItem}>
            <span>{item.name}</span>
            <span>{item.quantity}</span>
        </div>
    })

    const items = categories?.categories.map((category) => {
        const menuItemsCategory = menuItems?.items.filter(
            (item) => item.category_id === category.id
        );


        return {
            key: '' + category.id,
            label: category.name,
            children: (
                <div className={styles.items__container}>
                    {menuItemsCategory?.map((item, index) => {
                        const currentOrderData = order?.order_items?.find(order => order.item_id === item.id)
                        const itemQuantity = currentOrderData ? currentOrderData.count : 0;

                        return <React.Fragment key={index}>
                            <ItemCard
                                key={index}
                                handleItemMoneyAmountChange={handleFinalAmountChanged}
                                name={item.name}
                                price={item.price}
                                id={item.id}
                                itemKey={index}
                                itemQuantity={itemQuantity}
                            />
                        </React.Fragment>
                    })}
                </div>
            ),
        };
    });

    if (isCategoriesLoading || isItemsLoading || isOrderLoading) return <LoadingSpin />

    return <div>
                <div className={styles.buttonRowRight__container}>
                    <Button className={styles.button} onClick={handleCancelOrder} >
                        Назад
                    </Button>
                    {isCurrentOrderExist && <Button className={[styles.button, styles.red].join(' ')} onClick={() => currentOrderId && handleRemoveOrder(currentOrderId)} >
                        Удалить заказ
                    </Button>}
                </div>
                <Tabs size='large' onChange={setActiveTab} activeKey={activeTab} items={items} />

                <div className={styles.order}>{amountItems}</div>
                <Total
                totalAmount={totalAmount}
                isNewOrder={!currentOrderId}
                    workingDayId={workingDayId}
                    totalAmountWithDiscount={totalAmountWithDiscount}
                    isDiscountApplied={isDiscountApplied}
                    discount={discount}
                    setDiscount={setDiscount}
                    handlePaymentTypeChanged={handlePaymentTypeChanged}
                    paymentType={paymentType}
                    handleSendOrder={handleSendOrder}
                    handleUpdateOrder={handleUpdateOrder}
                    handleApplyDiscount={handleApplyDiscount}
                    handleCancelDiscount={handleCancelDiscount}
                    totalAmountByPositionWithQuantity={totalAmountByPositionWithQuantity}
                    handleMixedAmountCashChanged={setMixedAmounCash}
                    handleMixedAmountCardChanged={setMixedAmounCard}
                    mixedAmounCash={mixedAmounCash}
                    mixedAmounCard={mixedAmounCard}
                    isSendingOrder={isSendingOrder}
                />
            </div>
}