import { useState, useEffect } from 'react'
import { Table, Card, Spin, Tabs, Button } from 'antd';
import { useColumns } from './useColumns'
import styles from './closeWorkingDay.module.css';
import { MoneyInput } from '../MoneyInput';
import { useGetWorkingDayTotal } from '../../services/hooks/useGetWorkingDayTotal';
import { Status } from '../../types'
import { LoadingSpin } from '../LoadingSpin';
import { AmountInput } from '../AmountInput';


type CloseWorkingDayProps = {
    freeAmount: number | null;
    workingDayId: number;
    handleCloseWorkingDay: (freeAmount: number | null,  doughAmount: number | null) => void;
    handleCancelCloseWorkingDay: () => void;
    handleFreeAmountChanged: (value: number | null) => void;
    handleDoughAmountChange: (value: number | null)=> void;
    doughAmount: number| null;
}

export const CloseWorkingDay = ({ freeAmount, workingDayId, handleCloseWorkingDay, handleCancelCloseWorkingDay, handleFreeAmountChanged, handleDoughAmountChange, doughAmount }: CloseWorkingDayProps) => {
    const [activeTab, setActiveTab] = useState('sales');
    const [isFreeAmountApplied, setFreeAmountApplied] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountCash, setTotalAmountCash] = useState(0);
    const [totalAmountCard, setTotalAmountCard] = useState(0);
    const [totalAmountWithFree, setTotalAmountWithFree] = useState(0)

    const [totalInfo, statusTotalFetching, getTotal] = useGetWorkingDayTotal();

    const isTotalLoading = statusTotalFetching === Status.loading;
    const columns = useColumns();

    const handleApplyFreeAmount = () => {
        if (freeAmount && freeAmount > 0) {
            setTotalAmountWithFree(freeAmount + totalAmount)
            setFreeAmountApplied(true)
        }
    }

    const handleCancelFreeAmount = () => {
        setTotalAmountWithFree(totalAmount)
        setFreeAmountApplied(false);
    }
    useEffect(() => {
        getTotal(workingDayId)
    }, [])

    useEffect(() => {
        if (totalInfo) {
            if (totalInfo) {
                const totalCash = totalInfo.money?.cash ?? 0;
                const totalCard = totalInfo.money?.card ?? 0;
                const total = totalCash+totalCard;
                setTotalAmountCash(totalCash);
                setTotalAmountCard(totalCard);
                setTotalAmount(total)
                setTotalAmountWithFree(total + (freeAmount?? 0))
            }
        }
    }, [totalInfo])

    const items = [{
        key: 'sales',
        label: 'Продажи',
        children: (
            <>
            <Card
                bordered={false}
                size="small"
                title={!isTotalLoading ? 'Всего за смену' : <Spin size="small" />}>
                <Table
                    rowKey="id"
                    dataSource={totalInfo?.working_day_total}
                    loading={isTotalLoading}
                    columns={columns}
                    scroll={{ x: true }}
                />
            </Card>
            <div className={styles.dough}>
        <span>Тесто на конец смены</span>
        <AmountInput onChange={handleDoughAmountChange}/></div>
        </>
        )
    },
    {
        key: 'amount',
        label: 'Сумма',
        children: (
            <div className={styles.result}>
                <span className={styles.result__header}>Выручка за смену:</span>
                <span>{`Наличные: ${totalAmountCash}руб`}</span>
                <span>{`Карта: ${totalAmountCard}руб`}</span>
                <span>{`Всего: ${Math.floor(totalAmountWithFree)}руб`}</span>
                <MoneyInput amount={freeAmount} isAmountApplied={isFreeAmountApplied}
                    handleApplyAmount={handleApplyFreeAmount} handleCancelAmount={handleCancelFreeAmount}
                    title={'Добавить произвольную сумму'} onChange={handleFreeAmountChanged} />
            </div>
        )
    }
    ]

    return (
        <div className={styles.content}>
            {isTotalLoading ? <LoadingSpin /> : <Tabs onChange={setActiveTab} activeKey={activeTab} items={items} />}
            <div className={styles.buttons__container}>
                <Button className={[styles.closeWorkingDay, styles.button].join(' ')} type='primary' onClick={() => handleCloseWorkingDay(freeAmount, doughAmount)}>Закрыть смену</Button>
                <Button className={[styles.closeWorkingDay, styles.button].join(' ')} onClick={handleCancelCloseWorkingDay}>Отмена</Button></div>
        </div>
    )
}