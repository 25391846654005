import {Radio, RadioChangeEvent} from 'antd';
import styles from './paymentType.module.css'

type PaymentTypeProps = {
    onChange: (e: RadioChangeEvent) => void;
    paymentType: 'cash' | 'card' | 'mix';
}

export const PaymentType = ({onChange, paymentType}: PaymentTypeProps) => {
    return (
        <div className={styles.paymentType}>
            <span>Тип оплаты</span>
            <Radio.Group onChange={onChange} defaultValue={paymentType}>
                <Radio.Button className={styles.button} value="cash">Наличные</Radio.Button>
                <Radio.Button className={styles.button} value="card">Карта</Radio.Button>
                <Radio.Button className={styles.button} value="mix">Смешанная</Radio.Button>
            </Radio.Group>
        </div>
    )
}

