import { Button } from 'antd';
import styles from './moneyInput.module.css';
import { AmountInput } from '../AmountInput'

type MoneyInputProps = {
    isAmountApplied: boolean;
    amount: number | null;
    onChange: (value: number | null) => void;
    handleApplyAmount: () => void;
    handleCancelAmount: () => void;
    title: string
}
export const MoneyInput = ({ amount, isAmountApplied, onChange, handleApplyAmount, handleCancelAmount, title }: MoneyInputProps) => {
    return (
        <div className={styles.money}>
            <div className={styles.buttonsContainer}>
                <span>{title}</span>
                <AmountInput defaultValue={amount ?? undefined} onChange={(value) => value && value > -1 && onChange(value)} />
            </div>
            <div className={styles.buttonsContainer}>
                <Button className={styles.button} disabled={isAmountApplied || !amount} onClick={handleApplyAmount} type='primary'>Применить</Button>
                <Button className={styles.button} disabled={!isAmountApplied} onClick={handleCancelAmount}>Отменить</Button>
            </div>
        </div>
    )
}
