const reports = 'reports';
const order = 'order';
const manage = 'manage';
const login = 'login';

export const paths = {
    reports,
    order,
    manage,
    login
};

export const GMAPS_PATH = 'https://www.google.com/maps';
