import { Status } from '../../types';
import { createReducer } from 'typesafe-actions';
import { initializeAppActions, loginActions, logoutActions } from './actions';

export interface AppState {
  isLoggedIn: boolean;
  isLoggedOut: boolean;
  status: Status;
}

const initialState: AppState = {
  isLoggedIn: false,
  isLoggedOut: false,
  status: Status.initial,
};

export const app = createReducer(initialState)
  .handleAction(initializeAppActions.request, state => ({
    ...state,
    status: Status.loading,
  }))
  .handleAction(initializeAppActions.success, state => ({
    ...state,
    status: Status.success,
  }))
  .handleAction(initializeAppActions.failure, state => ({
    ...state,
    status: Status.failure,
  }))
  .handleAction(loginActions.request, state => ({
    ...state,
    status: Status.loading,
  }))
  .handleAction(loginActions.success, state => ({
    ...state,
    isLoggedIn: true,
  }))
  .handleAction(loginActions.failure, state => ({
    ...state,
    isLoggedIn: false,
    status: Status.failure,
  }))
  .handleAction(logoutActions.request, state => ({
    ...state,
    status: Status.loading,
  }))
  .handleAction(logoutActions.success, state => ({
    ...state,
    isLoggedIn: false,
    status: Status.success,
    isLoggedOut: true,
  }))
  .handleAction(logoutActions.failure, state => ({
    ...state,
    status: Status.failure,
  }));
