import { RequestException, ErrorCodes } from '../requests/exception';
import { loginActions } from '../state/app/actions';
import { RootState } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const handleError: OnError = async (dispatch, error) => {
  if (error instanceof RequestException)
    switch (error.code) {
      case ErrorCodes.UNAUTHORIZED:
        dispatch(loginActions.failure());
        break;

      case ErrorCodes.SERVER_ERROR:
      default:
        break;
    }
};

// function that take thunk and onError callback and wrap thunk with gloabl error handler
export const wrapThunk = (thunk: Thunk, onError: OnError): Thunk => async (dispatch, getState): Promise<void> => {
  try {
    await thunk(dispatch, getState);
  } catch (err) {
    //@ts-ignore
    onError && onError(dispatch, err, getState);
    //@ts-ignore
    handleError(dispatch, err, getState);
  }
};

export type ReduxDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type Thunk = (dispatch: ReduxDispatch, getState: () => RootState) => Promise<void>;
export type OnError = (
  dispatch: ReduxDispatch,
  error?: Error | RequestException,
  getState?: () => void,
) => Promise<void>;
